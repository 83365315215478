import React from "react";

export default ({ width = 26, height = 26, ...props }) => (
  <svg width={width} height={height} viewBox="0 0 26 26" fill="none">
    <path
      d="M23.8401 3.74178H18.4305V2.85979C18.4305 1.28289 17.1476 0 15.5707 0H10.4285C8.85156 0 7.56867 1.28289 7.56867 2.85979V3.74178H2.15913C1.75822 3.74178 1.4375 4.0625 1.4375 4.4634C1.4375 4.86431 1.75822 5.18503 2.15913 5.18503H3.4634V22.1406C3.4634 24.2681 5.19531 26 7.32278 26H18.6764C20.8039 26 22.5358 24.2681 22.5358 22.1406V5.18503H23.8401C24.241 5.18503 24.5617 4.86431 24.5617 4.4634C24.5617 4.0625 24.241 3.74178 23.8401 3.74178ZM9.01193 2.85979C9.01193 2.07936 9.64803 1.44326 10.4285 1.44326H15.5707C16.3512 1.44326 16.9873 2.07936 16.9873 2.85979V3.74178H9.01193V2.85979ZM21.0925 22.1406C21.0925 23.4716 20.0074 24.5567 18.6764 24.5567H7.32278C5.99178 24.5567 4.90666 23.4716 4.90666 22.1406V5.18503H21.0979V22.1406H21.0925Z"
      fill="currentColor"
    />
    <path
      d="M12.999 21.9693C13.3999 21.9693 13.7206 21.6486 13.7206 21.2477V8.4936C13.7206 8.0927 13.3999 7.77197 12.999 7.77197C12.5981 7.77197 12.2773 8.0927 12.2773 8.4936V21.2424C12.2773 21.6433 12.5981 21.9693 12.999 21.9693Z"
      fill="currentColor"
    />
    <path
      d="M8.29194 21.1728C8.69285 21.1728 9.01357 20.8521 9.01357 20.4511V9.28462C9.01357 8.88371 8.69285 8.56299 8.29194 8.56299C7.89104 8.56299 7.57031 8.88371 7.57031 9.28462V20.4511C7.57031 20.8521 7.89638 21.1728 8.29194 21.1728Z"
      fill="currentColor"
    />
    <path
      d="M17.7099 21.1728C18.1108 21.1728 18.4315 20.8521 18.4315 20.4511V9.28462C18.4315 8.88371 18.1108 8.56299 17.7099 8.56299C17.309 8.56299 16.9883 8.88371 16.9883 9.28462V20.4511C16.9883 20.8521 17.309 21.1728 17.7099 21.1728Z"
      fill="currentColor"
    />
  </svg>
);
